const REQUEST_API_URL = "http://54.180.112.237/api/";


const M_PWD = "159159";


export {
    REQUEST_API_URL,
    M_PWD
}
